<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/index' }">PC网站</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/product' }">产品管理</el-breadcrumb-item>
      <el-breadcrumb-item>编辑产品</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.pageForm.title}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form class="middle-form" :rules="pageRules" ref="formRef" :model="pageForm" label-width="120px">
        <el-form-item label="排序" prop="sort">
          <el-input type="number" v-model="pageForm.sort" placeholder="数字越大越靠前"></el-input>
        </el-form-item>
        <el-form-item label="产品名称" prop="title">
          <el-input v-model="pageForm.title" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="产品ICON(100*100)" prop="icon">
          <upload :url="pageForm.icon" :filed="'icon'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="产品亮点" prop="light">
          <el-select v-model="pageForm.light" multiple filterable allow-create default-first-option placeholder="请输入产品亮点,按回车继续输入">
            <el-option label="按回车键继续创建条目，不建议超过3条" value="无"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品描述" prop="desc">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入产品描述" v-model="pageForm.desc"></el-input>
        </el-form-item>
        <el-form-item label="滚动图" prop="banner">
          <upload :urlArr="pageForm.banner" :filed="'banner'" :max-num="3" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="功能介绍列表" prop="funlist">
          <div class="fun-list">
            <div class="fun-item" v-for="(item, index) in pageForm.funlist" :key="index" :index="item.sort">
              <div class="info-panle">
                <div class="left">
                  <img :src="item.img"/>
                </div>
                <div class="middle">
                  <div class="fun-title">{{item.title}}</div>
                  <div class="fun-desc-item" v-for="(descitem, index2) in item.desclist" :key="index2">{{descitem}}</div>
                </div>
              </div>
              <div class="tools">
                <div class="tool-item">
                  <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editFuncItem(item, index)"></el-button>
                </div>
                <div class="tool-item">
                  <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="delFuncItem(index)"></el-button>
                </div>
              </div>
            </div>
          </div>
          <div class="add-new-func">
            <el-button type="primary" icon="el-icon-plus" @click="addFuncVisible = true" size="mini">添加新描述</el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">修改设置</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 添加新描述表单 -->
    <el-dialog title="添加新描述" :visible.sync="addFuncVisible" width="30%" :before-close="addFuncClose">
      <el-form class="func-form" :rules="funcRules" ref="funcRef" :model="funcForm" label-width="120px">
        <el-form-item label="排序" prop="sort">
          <el-input type="number" v-model="funcForm.sort" placeholder="数字越大越靠前"></el-input>
        </el-form-item>
        <el-form-item label="描述标题" prop="title">
          <el-input v-model="funcForm.title" placeholder="请输入描述标题"></el-input>
        </el-form-item>
        <el-form-item label="描述图" prop="img">
          <upload :url="funcForm.img" :filed="'img'" :max-num="1" @checked-files="checkedFuncFiles" @del-select="delFuncSelect"></upload>
        </el-form-item>
        <el-form-item label="功能描述小点" class="flex-form-item">
          <div class="input-area">
            <el-input v-model="descing" placeholder="请输入功能描述小点"></el-input>
            <el-button @click.prevent="addFuncItem(descing)">添加</el-button>
          </div>
          <div class="added-list" prop="desclist">
            <div class="added-item" v-for="(item, index) in funcForm.desclist" :key="index">
              <div class="text">
                {{item}}
              </div>
              <div class="del-item">
                <el-button type="danger" size="mini" icon="el-icon-delete" circle @click="delDesc(index)"></el-button>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddDescItem">取 消</el-button>
        <el-button type="primary" @click="addDescItemSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑新描述表单 -->
    <el-dialog title="编辑描述" :visible.sync="editFuncVisible" width="30%" :before-close="addFuncClose">
      <el-form class="func-form" :rules="editFuncRules" ref="editFuncRef" :model="editFuncForm" label-width="120px">
        <el-form-item label="排序" prop="sort">
          <el-input type="number" v-model="editFuncForm.sort" placeholder="数字越大越靠前"></el-input>
        </el-form-item>
        <el-form-item label="描述标题" prop="title">
          <el-input v-model="editFuncForm.title" placeholder="请输入描述标题"></el-input>
        </el-form-item>
        <el-form-item label="描述图" prop="img">
          <upload :url="editFuncForm.img" :filed="'img'" :max-num="1" @checked-files="checkedEditFuncFiles" @del-select="delFuncEditSelect"></upload>
        </el-form-item>
        <el-form-item label="功能描述小点" class="flex-form-item">
          <div class="input-area">
            <el-input v-model="descing" placeholder="请输入功能描述小点"></el-input>
            <el-button @click.prevent="addEditFuncItem(descing)">添加</el-button>
          </div>
          <div class="added-list" prop="desclist">
            <div class="added-item" v-for="(item, index) in editFuncForm.desclist" :key="index">
              <div class="text">
                {{item}}
              </div>
              <div class="del-item">
                <el-button type="danger" size="mini" icon="el-icon-delete" circle @click="delEditDesc(index)"></el-button>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEditDescItem">取 消</el-button>
        <el-button type="primary" @click="editDescItemSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '@/components/common/Upload'
export default {
  name: 'Edit',
  components: {
    Upload
  },
  data () {
    return {
      pageForm: {
        id: this.$route.params.id,
        sort: 0,
        title: '',
        icon: '',
        light: [],
        desc: '',
        banner: [],
        funlist: []
      },
      pageRules: {
        title: [
          { required: true, message: '请输入产品名称', trigger: 'blur' },
          { min: 1, max: 25, message: '长度在 3 到 25 个字符', trigger: 'blur' }
        ],
        banner: [
          { required: true, message: '请上传滚动图', trigger: 'blur' }
        ],
        icon: [
          { required: true, message: '请上传产品ICON(100*100)', trigger: 'blur' }
        ]
      },
      addFuncVisible: false,
      funcForm: {
        sort: 0,
        title: '',
        img: '',
        desclist: []
      },
      funcRules: {
        title: [
          { required: true, message: '请输入描述标题', trigger: 'blur' },
          { min: 1, max: 25, message: '长度在 3 到 25 个字符', trigger: 'blur' }
        ],
        banner: [
          { required: true, message: '请上传描述图', trigger: 'blur' }
        ]
      },
      editFuncVisible: false,
      editFuncRules: {
        title: [
          { required: true, message: '请输入描述标题', trigger: 'blur' },
          { min: 1, max: 25, message: '长度在 3 到 25 个字符', trigger: 'blur' }
        ],
        banner: [
          { required: true, message: '请上传描述图', trigger: 'blur' }
        ]
      },
      editFuncForm: {
        index: 0,
        sort: 0,
        title: '',
        img: '',
        desclist: []
      },
      descing: ''
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      const { data: res } = await this.$http.get('/pc-product', { params: { id: this.$route.params.id } })
      if (res.status === 200) {
        this.pageForm.sort = res.data.sort
        this.pageForm.title = res.data.title
        this.pageForm.icon = res.data.icon
        this.pageForm.light = res.data.light
        this.pageForm.desc = res.data.desc
        this.pageForm.banner = res.data.banner
        this.pageForm.funlist = res.data.funlist
      } else {
        this.$message.error(res.msg)
      }
    },
    onSubmit () {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('/pc-product', this.pageForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            await this.$router.push('/admin/apps/pc/product')
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 添加功能描述小点
    addFuncItem (text) {
      if (!text) {
        this.$message.error('请输入内容')
        return false
      }
      this.funcForm.desclist.push(text)
      this.descing = ''
    },
    addEditFuncItem (text) {
      if (!text) {
        this.$message.error('请输入内容')
        return false
      }
      this.editFuncForm.desclist.push(text)
      this.descing = ''
    },
    cancel () {
      this.$router.push('/admin/apps/pc/product')
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    },
    // 上传组件返回值
    checkedFuncFiles (data) {
      this.funcForm.img = data.url
    },
    // 删除已选择文件
    delFuncSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.funcForm.img = ''
      } else {
        // 多文件删除
        const checkedIndex = this.funcForm.img.findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.funcForm.img.splice(checkedIndex, 1)
      }
    },
    // 上传组件返回值
    checkedEditFuncFiles (data) {
      this.editFuncForm.img = data.url
    },
    // 删除已选择文件
    delFuncEditSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.editFuncForm.img = ''
      } else {
        // 多文件删除
        const checkedIndex = this.editFuncForm.img.findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.editFuncForm.img.splice(checkedIndex, 1)
      }
    },
    // 关闭弹窗
    addFuncClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    cancelAddDescItem () {
      this.$refs.funcRef.resetFields()
    },
    cancelEditDescItem () {
      this.$refs.editFuncRef.resetFields()
    },
    addDescItemSubmit () {
      this.pageForm.funlist.push(JSON.parse(JSON.stringify(this.funcForm)))
      this.$refs.funcRef.resetFields()
      this.funcForm.desclist = []
      this.addFuncVisible = false
    },
    editDescItemSubmit () {
      this.pageForm.funlist[this.editFuncForm.index] = JSON.parse(JSON.stringify(this.editFuncForm))
      this.$refs.editFuncRef.resetFields()
      this.editFuncForm.desclist = []
      this.editFuncVisible = false
    },
    delDesc (index) {
      this.funcForm.desclist.splice(index, 1)
    },
    delEditDesc (index) {
      this.editFuncForm.desclist.splice(index, 1)
    },
    // 编辑项目
    editFuncItem (item, index) {
      this.editFuncForm.index = index
      this.editFuncForm.sort = item.sort
      this.editFuncForm.title = item.title
      this.editFuncForm.img = item.img
      this.editFuncForm.desclist = item.desclist
      this.editFuncVisible = true
    },
    // 删除项目
    delFuncItem (index) {
      this.pageForm.funlist.splice(index, 1)
    }
  }
}
</script>

<style scoped>
.middle-form{
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}
/deep/.el-radio{
  margin-bottom: 14px;
}
.flex-form-item .input-area{
  display: flex;
}
.flex-form-item .input-area .el-button{
  margin-left: 10px;
}
.added-list{}
.added-list .added-item{
  padding: 0 6px;
  border: 1px dashed rgba(0,0,0,0.2);
  margin: 6px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.added-list .added-item .text{}
.added-list .added-item .del-item{}
.fun-list{}
.fun-list .fun-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0,0,0,0.1);
  border-radius: 6px;
  padding: 4px;
  margin-bottom: 10px;
}
.fun-item .info-panle{
  display: flex;
}
.fun-list .fun-item .left{
  width: 120px;
}
.fun-list .fun-item .left img{
  width: 100%;
}
.fun-list .fun-item .middle{
  text-align: left;
  padding-left: 10px;
}
.fun-list .fun-item .middle .fun-title{
  font-size: 15px;
  font-weight: bold;
}
.fun-list .fun-item .middle .fun-desc-item{
  font-size: 14px;
  color: rgba(0,0,0,.6);
  line-height: 1;
}
.fun-list .fun-item .tools{
  display: flex;
  flex-direction: column;
}
</style>
